import React from 'react'

import { Alert } from '@hub/alert'

import useNotification from '../../lib/use-notification'

const Notification: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { closeNotification, notification } = useNotification()

  return (
    <>
      {notification && (
        <Alert
          message={notification.message}
          status={notification.type}
          icon={true}
          onClose={closeNotification}
        />
      )}
    </>
  )
}

export default Notification
