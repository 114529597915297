import React from 'react'
import { useRecoilValue } from 'recoil'
import { Button } from '@hub/button'
import { Grid, GridItem } from '@hub/grid'
import { H3 } from '@hub/heading'
import {
  CarMediumIcon,
  UserMediumIcon,
  ShoppingCartMediumIcon,
  NotificationMediumIcon,
  LocationDropPinMediumIcon,
} from '@hub/icon'
import { Link } from '@hub/link'
import { Stack } from '@hub/stack'
import InfoGridTile from '../info-grid-tile'
import SpinnerDelayed from '../spinner-delayed'
import useIsMounted from '@scentregroup/shared/hooks/use-is-mounted'
import {
  incBaseAccount,
  LOGOUT,
  ORDERS,
  PARKING,
  PREFERENCES,
  PROFILE,
  PREFERRED_CENTRE,
} from '../../lib/paths'
import { userAtom } from '../../lib/atoms/user-info'
import { CONFIG_KEYS, getClientConfigBool } from '@scentregroup/shared/config'

const isPreferredCentreEnabled = getClientConfigBool(
  CONFIG_KEYS.NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE
)

const infoGridData = [
  {
    title: 'Profile',
    icon: UserMediumIcon,
    link: incBaseAccount(`/${PROFILE}`),
    description: 'Contact details and name',
  },
  ...(isPreferredCentreEnabled
    ? [
        {
          title: 'Preferred centre',
          icon: LocationDropPinMediumIcon,
          link: incBaseAccount(`/${PREFERRED_CENTRE}`),
          description: 'Your preferred Westfield centre',
        },
      ]
    : []),
  {
    title: 'Orders',
    icon: ShoppingCartMediumIcon,
    link: incBaseAccount(`/${ORDERS}`),
    description: 'Past online orders',
  },
  {
    title: 'Parking',
    icon: CarMediumIcon,
    link: incBaseAccount(`/${PARKING}`),
    description: 'Review saved number plates',
  },
  {
    title: 'Preferences',
    icon: NotificationMediumIcon,
    link: incBaseAccount(`/${PREFERENCES}`),
    description: 'Marketing and notifications',
  },
]

const InfoGrid: React.FC<React.PropsWithChildren> = () => {
  const { data } = useRecoilValue(userAtom)

  const firstName = data?.firstName || ''
  const userHasMarketplaceOrders = data?.hasMarketplaceOrders || false
  const isBrowser = useIsMounted()

  return (
    <>
      <H3 sx={{ marginBottom: ['spacing-md', null, 'spacing-lg'] }}>
        Welcome{firstName && `, ${firstName}`}
      </H3>
      {isBrowser ? (
        <Stack gap={['spacing-md', null, 'spacing-lg']}>
          <Grid
            templateColumns={'repeat(3, 1fr)'}
            gap={['spacing-sm', 'spacing-sm', 'spacing-md']}
          >
            {infoGridData
              .filter(
                item =>
                  (item.title.toLowerCase() === 'orders' &&
                    userHasMarketplaceOrders) ||
                  !['orders'].includes(item.title.toLowerCase())
              )
              .map(item => (
                <GridItem
                  colSpan={[3, 3, 1]}
                  sx={{
                    outline: '1px solid',
                    outlineColor: 'borderSecondary',
                    _hover: { bg: 'surfaceBrandPrimary' },
                  }}
                  key={item.title}
                >
                  <InfoGridTile
                    title={item.title}
                    Icon={item.icon}
                    link={item.link}
                    description={item.description}
                  />
                </GridItem>
              ))}
          </Grid>
          <Button
            variant="link"
            as={Link}
            href={incBaseAccount(`/${LOGOUT}?path=/`)}
            sx={{
              textDecoration: 'underline',
              fontWeight: 'normal',
              padding: 'spacing-none',
              height: 'size-8',
              textTransform: 'none',
              color: 'textPrimary',
              _hover: {
                color: 'textSecondary',
              },
            }}
          >
            Sign out
          </Button>
        </Stack>
      ) : (
        <SpinnerDelayed />
      )}
    </>
  )
}

export default InfoGrid
